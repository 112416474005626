<template>
  <div class="avatar-list">
    <template v-for="{ src, title } of images.slice(0, 3)">
      <component
        v-if="isSvg(src)"
        :key="title"
        :width="size"
        :height="size"
        :class="['avatar-list__item', { greyscale }]"
        :is="src"/>
      <img
        v-else
        :key="title"
        :src="src"
        :title="title"
        :alt="title"
        :class="['avatar-list__item', { greyscale }]">
    </template>
    <div
      v-if="images.length > 3"
      class="avatar-list__item avatar-list__item--empty d-flex justify-center align-center">
      +{{ images.length - 3 }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CyCondensedAvatar',
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    greyscale: {
      type: Boolean,
      default: false,
    },
    size: {
      type: [String, Number],
      default: '32',
    },
  },
  methods: {
    isSvg (src) {
      return _(src).keys().includes('render')
    },
  },
}
</script>

<style lang="scss" scoped>
  .avatar-list {
    display: inline-flex;

    @for $i from 1 through 4 {
      :nth-child(#{$i}) {
        transform: translateX(($i - 1) * -16px);
      }
    }

    &:hover {
      @for $i from 1 through 4 {
        :nth-child(#{$i}) {
          transform: translateX(($i - 1) * -8px);
          filter: grayscale(0);
        }
      }
    }

    &__item {
      width: 32px;
      height: 32px;
      padding: 4px;
      transition: transform 0.3s;
      border: 1px solid get-color("grey");
      border-radius: 50%;
      background-color: get-color("white");
      cursor: default;

      &--empty {
        background-color: get-color("grey", "light-3");
        color: get-color("grey", "dark-3");
        font-size: map.get($font-sizes, "base");
        font-weight: $font-weight-bold;
      }

      &.greyscale {
        filter: grayscale(1);
      }
    }

    &.clickable {
      .avatar-list__item {
        cursor: pointer;
      }
    }
  }
</style>
