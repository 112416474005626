<template>
  <v-card
    :class="['summary-banner py-4 px-6 mb-8', {
      'summary-banner--clickable': providerDatas.length === 1,
    }]"
    :ripple="providerDatas.length === 1"
    @click="gotoProviderDetail">
    <div
      v-if="!loading"
      :class="['d-flex flex-column', {
        'align-start': !totals.cost,
      }]">
      <div class="mb-4">
        <span class="summary-banner__title font-weight-bold">
          {{ $t('untranslated.total') }} ({{ getCurrencySymbol(currency) }})
        </span>
        <span
          v-if="providerDatas.length === 1"
          class="summary-banner__title font-weight-bold">
          {{ getProviderExtraInfoByCanonical[providerDatas[0].canonical].displayName }}
        </span>
        <span class="summary-banner__date-range ml-2">
          {{ dateRange.begin }} - {{ dateRange.end }}
        </span>
      </div>

      <div
        v-if="totals.cost"
        class="d-flex justify-space-between">
        <div
          :class="['summary-banner__total-costs', {
            'mt-5': !_.isEmpty(providerDatas) && providerDatas.length === 1,
          }]">
          <h1 class="total d-flex align-end">
            <span class="total__cost">
              <span class="total__cost--number">
                {{ formatAmount(totals.cost) }}
              </span>
              <span class="total__cost--currency ml-2">
                {{ getCurrencySymbol(currency) }}
              </span>
            </span>
            <span class="total__excl-tax font-weight-regular ml-2 mb-2">
              {{ $t('cloudCostManagement.exclTax') }}
            </span>
          </h1>

          <CyCloudCostManagementTrendChip
            v-if="totals.cost"
            class="mr-2"
            :value="[totals.oldestCost, totals.latestCost]"/>

          <span class="total__difference">
            {{ formatAmount(totals.difference, { currency, signDisplay: 'always' }).replace('+', '+ ') }}
          </span>

          <div
            v-if="!_.isEmpty(providerDatas) && providerDatas.length !== 1"
            class="total__pie-graph mt-4 ml-2">
            <ECharts
              ref="pieChart"
              class="pie-chart"
              theme="cycloid"
              autoresize
              :option="options.pieChart"/>
            <div class="pie-graph__legend">
              <span
                v-for="({ canonical }, index) in providerDatas"
                :key="canonical"
                @mouseenter="highlightProvider(canonical)"
                @mouseleave="downplayProvider(canonical)">
                <span
                  class="marker"
                  :style="{ 'background-color': chartLegendColors[index] }"/>
                <span class="pie-graph__legend--canonical">{{ getProviderExtraInfoByCanonical[canonical].displayName }} </span>
                <span class="pie-graph__legend--percentage">{{ formatPercentage(getProviderPercentage(canonical)) }}</span>
              </span>
            </div>
          </div>
        </div>

        <div class="summary-banner__line-graph">
          <ECharts
            ref="lineChart"
            class="line-chart"
            theme="cycloid"
            autoresize
            :option="options.lineChart"/>
        </div>
      </div>

      <div
        v-if="providerDatas.length === 1"
        class="summary-banner__explore-costs d-flex justify-space-between align-center font-weight-normal pt-4 px-6 mt-4 mx-n6">
        <span class="text">
          {{ $t('cloudCostManagement.exploreCosts') }}
        </span>
        <v-icon>
          chevron_right
        </v-icon>
      </div>

      <CyTag
        v-if="!totals.cost"
        variant="primary"
        icon-before="info">
        {{ $t('noTotalCost') }}
      </CyTag>
    </div>

    <div
      v-else
      class="sk-template d-flex flex-column">
      <div class="d-flex flex-row justify-space-between mb-4">
        <div class="sk-block sk-title sk-w-70 sk-h-6"/>
        <div class="sk-block sk-title sk-w-10 sk-h-6 sk-dark"/>
      </div>
      <div class="d-flex justify-space-between">
        <div class="d-flex flex-column">
          <div class="d-flex">
            <div class="sk-block sk-title sk-w-35 sk-h-8 sk-dark"/>
            <div class="sk-block sk-title sk-w-15 ml-2"/>
          </div>
          <div class="sk-block sk-title sk-w-20 mt-2"/>
          <div class="d-flex">
            <div class="sk-block sk-avatar sk-w-20 sk-h-20 sk-dark mt-6"/>
            <div class="mt-8">
              <div class="sk-block sk-title sk-w-20 sk-h-4 ml-2 ml-4 mb-2"/>
              <div class="sk-block sk-title sk-w-20 sk-h-4 ml-2 ml-4 mb-2"/>
              <div class="sk-block sk-title sk-w-20 sk-h-4 ml-2 ml-4 mb-2"/>
            </div>
          </div>
        </div>
        <div class="sk-block sk-card width-50 sk-h-43"/>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getCurrencySymbol, formatAmount } from '@/utils/helpers'
import { formatPercentage, getTotalsFromAggregateData, chartLegendColors } from '@/utils/helpers/cloud-cost-management'
import CyCloudCostManagementTrendChip from '@/components/cloud-cost-management/trend-chip'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment

export default {
  name: 'CyCloudCostManagementSummaryBanner',
  components: {
    CyCloudCostManagementTrendChip,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    series: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    chartLegendColors,
  }),
  computed: {
    ...mapState('organization/cloudCostManagement', {
      aggregateData: (state) => state.aggregateData,
      currency: (state) => state.queryBody.currency,
      queryBody: (state) => state.queryBody,
    }),
    ...mapGetters('organization/cloudCostManagement', [
      'getProviderExtraInfoByCanonical',
    ]),
    dateRange () {
      const { begin, end } = this.queryBody

      return _.mapValues({
        begin,
        end,
      }, (date) => _.upperFirst(moment(date, 'YYYY-MM-DD').locale(this.$i18n.locale).format('MMM DD, YYYY'))) // eslint-disable-line you-dont-need-momentjs/format
    },
    providerDatas () {
      return _.get(this.aggregateData, 'providerDatas', [])
    },
    totals () {
      return getTotalsFromAggregateData(this.aggregateData)
    },
    options () {
      return {
        pieChart: {
          color: chartLegendColors,
          series: [
            {
              avoidLabelOverlap: false,
              center: ['35%', '50%'],
              data: _.map(this.providerDatas, ({ canonical, cost }) => ({
                name: canonical,
                value: cost,
              })),
              emphasis: {
                scale: true,
                scaleSize: 3,
              },
              itemStyle: {
                borderColor: '#fff',
                borderWidth: 1,
              },
              label: {
                show: false,
                position: 'center',
              },
              labelLine: {
                show: false,
              },
              radius: ['40%', '70%'],
              type: 'pie',
            },
          ],
          tooltip: {
            confine: false,
            formatter: this.formatPieChartTooltip,
            position: ['0%', '100%'],
          },
        },
        lineChart: {
          color: chartLegendColors,
          grid: {
            top: 12,
            right: 0,
            bottom: 0,
            left: 25,
            containLabel: true,
          },
          series: this.series.map(({ name, costData }) => ({
            name,
            data: _.map(costData, ({ cost, date }) => [date, cost]),
            symbol: 'none',
            type: 'line',
            stack: 'Total',
            lineStyle: {
              width: 0,
            },
            areaStyle: {
              opacity: 0.45,
            },
            emphasis: {
              areaStyle: {
                opacity: 1,
              },
            },
            silent: true,
          })),
          tooltip: {
            trigger: 'axis',
            formatter: this.formatLineChartTooltip,
          },
          xAxis: {
            axisLabel: {
              formatter: (value) => $date.format(Number(value), this.queryBody.granularity === 'month' ? 'MMM' : 'MMM d'),
            },
            type: 'category',
            boundaryGap: false,
          },
          yAxis: {
            axisLabel: {
              formatter: (value) => this.formatAmount(value, { decimalPlaces: 0 }),
            },
            type: 'value',
            position: 'right',
            splitNumber: 3,
            boundaryGap: [0, 0],
          },
        },
      }
    },
  },
  methods: {
    getCurrencySymbol,
    formatAmount,
    formatPercentage,
    getTooltipListItem (marker, provider, cost, percentage) {
      const { currency } = this
      return `
        <span class="marker-slot">${marker || ''}</span>
        <span class="provider-canonical">${provider}:</span>
        <strong class="cost mr-2">${formatAmount(cost, { currency })}</strong>
        <span class="percentage">${percentage ? `(${formatPercentage(percentage)})` : ''}</span>`
    },
    formatLineChartTooltip (providersData) {
      const periodTotal = _.sumBy(providersData, 'data[1]')
      const providerDatasMarkup = providersData.map(({ seriesName: canonical, data: [_date, cost] }, index) => {
        const marker = this.providerDatas.length > 1
          ? `<span class="marker" style="background-color:${chartLegendColors[index]}"></span>`
          : ''
        return this.getTooltipListItem(
          marker,
          this.getProviderExtraInfoByCanonical[canonical].displayName,
          cost,
          periodTotal ? (cost * 100 / periodTotal) : 0,
        )
      })
      return `
        <p class="date">${$date.format(providersData[0].data[0], 'MMM d, yyyy')}</p>
        <div class="chart-tooltip__list">
          ${this.getTooltipListItem(null, this.$t('untranslated.total'), periodTotal, null)}
          ${providerDatasMarkup.join('')}
        </div>`
    },
    formatPieChartTooltip () {
      const providerDatasMarkup = this.providerDatas.map(({ canonical, cost }, index) => {
        const marker = this.providerDatas.length > 1
          ? `<span class="marker" style="background-color:${chartLegendColors[index]}"></span>`
          : ''
        return this.getTooltipListItem(
          marker,
          this.getProviderExtraInfoByCanonical[canonical].displayName,
          cost,
          this.getProviderPercentage(canonical),
        )
      })
      return `
        <div class="chart-tooltip__list">
          ${providerDatasMarkup.join('')}
        </div>`
    },
    getProviderPercentage (providerCanonical) {
      const providerCost = _.find(this.providerDatas, ({ canonical }) => canonical === providerCanonical)?.cost || 0
      return providerCost * 100 / this.totals.cost
    },
    gotoProviderDetail () {
      if (this.providerDatas.length === 1) {
        this.$emit('goto-provider-detail', this.providerDatas[0].canonical)
      }
    },
    highlightProvider (providerCanonical) {
      this.$refs.pieChart.dispatchAction({ type: 'highlight', name: providerCanonical })
      this.$refs.lineChart.dispatchAction({ type: 'highlight', seriesName: providerCanonical })
    },
    downplayProvider (providerCanonical) {
      this.$refs.pieChart.dispatchAction({ type: 'downplay', name: providerCanonical })
      this.$refs.lineChart.dispatchAction({ type: 'downplay', seriesName: providerCanonical })
    },
  },
  i18n: {
    messages: {
      en: {
        noTotalCost: 'No total cost for selected criteria',
        totalCosts: 'Total costs',
      },
      es: {
        noTotalCost: 'Sin costo total para los criterios seleccionados',
        totalCosts: 'Costos totales',
      },
      fr: {
        noTotalCost: 'Pas de coût total pour les critères sélectionnés',
        totalCosts: 'Coûts totaux',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-banner {
  padding-top: 18.5px;
  overflow: visible;
  border-radius: 8px;
  background-color: get-color("white");
  box-shadow: 0 1px 5px rgb(37 52 73 / 20%), 0 1px 1px rgb(37 52 73 / 14%), 0 1px 3px rgb(37 52 73 / 12%);
  color: get-color("primary");
  cursor: default;

  &:not(.summary-banner--clickable):focus::before {
    opacity: 0;
  }

  &__title {
    color: get-color("primary");
    font-size: map.get($font-sizes, "lg");
  }

  &__date-range {
    color: get-color("primary", "light-3");
    font-size: map.get($font-sizes, "base");
  }

  &__explore-costs {
    border-top: solid 1px get-color("grey", "light-1");

    .text {
      transition: color 0.2s ease;
      color: get-color("grey", "dark-2");
    }

    .v-icon {
      position: relative;
      transition: color 0.2s ease, transform 0.2s ease;
      color: get-color("grey", "dark-1");
    }
  }

  &__line-graph {
    width: 65%;
    height: 171px;

    .line-chart {
      width: 100%;
      height: 171px;
    }
  }

  &__provider-icon {
    display: inline-flex;
  }

  &__total-costs {
    .total {
      &__cost {
        font-family: "Roboto Condensed", $font-family-base;
        white-space: nowrap;

        &--number {
          color: get-color("primary");
          font-size: 40px;
          line-height: 100%;
        }

        &--currency {
          color: get-color("primary", "light-2");
          font-size: map.get($font-sizes, "xl");
        }
      }

      &__difference {
        opacity: 0;
        color: get-color("primary", "light-2");
        font-size: map.get($font-sizes, "base");
      }

      &__excl-tax {
        color: get-color("primary", "light-3");
        font-size: map.get($font-sizes, "sm");
        white-space: nowrap;
      }

      &__pie-graph {
        .pie-chart {
          position: relative;
          top: -10px;
          width: 100px;
          height: 92px;
        }
      }
    }
  }

  .total__pie-graph {
    display: flex;
    align-items: center;

    .pie-graph__legend {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;

      .marker {
        display: inline-block;
        position: relative;
        top: 1px;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border: solid 1px white;
        border-radius: 10px;
      }

      > span:hover {
        background-color: get-color("primary", "light-5");
      }

      &--canonical {
        color: get-color("primary");
      }

      &--percentage {
        color: get-color("primary", "light-2");
      }
    }
  }

  &__line-graph .line-chart,
  .total__pie-graph .pie-chart {
    ::v-deep {
      .marker {
        display: inline-block;
        position: relative;
        top: 1px;
        width: 10px;
        height: 10px;
        margin-right: 5px;
        border: solid 1px white;
        border-radius: 10px;
      }

      > div {
        cursor: pointer !important;
      }

      .chart-tooltip__list {
        display: grid;
        grid-template-columns: 20px auto 1fr auto;
        gap: 2px;
        list-style-type: none;

        .provider-canonical {
          margin-right: 10px;
          margin-left: -5px;
        }

        .cost {
          text-align: right;
        }

        .percentage {
          color: get-color("white");
        }
      }

      .date {
        margin-bottom: 2px;
      }
    }
  }

  &--clickable {
    transition: box-shadow 0.2s ease-out;
    cursor: pointer;

    &:hover,
    &:focus {
      box-shadow: map.get($box-shadow, card);
      cursor: pointer;

      .text,
      .v-icon {
        color: get-color("primary");
      }

      .v-icon {
        transform: translateX(4px);
      }
    }
  }
}
</style>
