<template>
  <div class="layout__container d-flex mr-n8 ml-n8">
    <div class="layout__content-wrapper">
      <div class="layout__content">
        <CyCloudCostManagementDashboardNotifications/>

        <div class="summary ml-n6">
          <CyCloudCostManagementSummaryBanner
            class="ml-6"
            :loading="isLoading"
            :series="_.get(aggregateData, 'providerDatas', []).map(({ canonical, costData }) => ({ name: canonical, costData }))"
            @goto-provider-detail="gotoProviderDetail"/>

          <CyCloudCostManagementEmissionsSummary
            v-if="isLoading || !_.isEmpty(_.get(aggregateData, 'providerDatas', []))"
            class="mb-8 ml-6"
            :loading="isLoading"/>
        </div>

        <h2
          v-if="dashboardDataLoading || hasEnoughProviderDatas"
          class="layout__providers-title py-4">
          {{ $t('providersBreakdown') }}
        </h2>

        <div
          v-if="!isLoading && hasEnoughProviderDatas"
          class="layout__provider-cards">
          <CyCloudCostManagementProviderCard
            v-for="providerData of _.get(aggregateData, 'providerDatas', [])"
            :key="providerData.canonical"
            :provider-data="providerData"
            :loading="isLoading"
            @goto-provider-detail="gotoProviderDetail"/>
        </div>

        <div
          v-else-if="dashboardDataLoading"
          class="layout__provider-cards">
          <CyCloudCostManagementProviderCard
            v-for="i of 2"
            :key="`provider-card-skeleton-${i}`"
            :provider-data="{}"
            :loading="isLoading"
            @goto-provider-detail="gotoProviderDetail"/>
        </div>

        <CyCloudCostManagementGraphsSwitcher
          v-if="!_.isEmpty(topProjects.histograms) || dashboardDataLoading"
          :graphs="['bar', 'line']"
          :hide-graphs="!hasTagMapping"
          :loading="isLoading"
          :series="series"
          :totals="seriesTotals"
          context="topProjects">
          <template slot="header">
            <h2 class="layout__graph-title">
              {{ $t('overview') }}
            </h2>
          </template>
          <template slot="footer">
            <CyCloudCostManagementTagMappingNotFound v-if="!isLoading && !hasTagMapping"/>
            <CyCloudCostManagementTopProjectsTable
              v-else
              :loading="isLoading"
              :top-items="_.keys(getTrimmedSeries(series, graphOrderingParam))"
              class="elevation-0 rounded-0"/>
          </template>
        </CyCloudCostManagementGraphsSwitcher>

        <div v-else>
          <h2>{{ $t('Projects') }}</h2>
          <v-card class="d-flex align-center flex-column pa-6 mt-4">
            <div class="no-projects__title mb-4">
              {{ $t('noProjectsFound') }}
            </div>
            <div class="no-projects__subtitle">
              {{ $t('tryDifferentFilters') }}
            </div>
          </v-card>
        </div>
      </div>
    </div>

    <div class="layout__sidebar pb-6">
      <CyCloudCostManagementDashboardFiltersSidebar @change="fetchDashboardData"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { constructBreadcrumb } from '@/utils/helpers'
import { getTrimmedSeries } from '@/utils/helpers/cloud-cost-management'
import { parseToGraphFormat } from '@/utils/api/parsers/cloud-cost-management/topProjects'
import CyCloudCostManagementDashboardFiltersSidebar from '@/components/cloud-cost-management/dashboard-filters-sidebar'
import CyCloudCostManagementGraphsSwitcher from '@/components/cloud-cost-management/graphs-switcher'
import CyCloudCostManagementTagMappingNotFound from '@/components/cloud-cost-management/tag-mapping-not-found'
import CyCloudCostManagementDashboardNotifications from '@/components/cloud-cost-management/dashboard-notifications'
import CyCloudCostManagementTopProjectsTable from '@/components/cloud-cost-management/top-projects-table'
import CyCloudCostManagementProviderCard from '@/components/cloud-cost-management/provider-card'
import CyCloudCostManagementSummaryBanner from '@/components/cloud-cost-management/summary-banner'
import CyCloudCostManagementEmissionsSummary from '@/components/cloud-cost-management/emissions-summary'

export default {
  name: 'CyPageCloudCostManagementDashboard',
  components: {
    CyCloudCostManagementDashboardFiltersSidebar,
    CyCloudCostManagementGraphsSwitcher,
    CyCloudCostManagementTagMappingNotFound,
    CyCloudCostManagementDashboardNotifications,
    CyCloudCostManagementTopProjectsTable,
    CyCloudCostManagementProviderCard,
    CyCloudCostManagementSummaryBanner,
    CyCloudCostManagementEmissionsSummary,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.cloudCostManagementDashboard'), [
      {
        name: 'cloudCostManagementSection',
        label: this.$t('routes.cloudCostManagement'),
      },
    ])
  },
  header () {
    return {
      title: this.$t('routes.cloudCostManagementTitle'),
      description: {
        text: this.$t('routes.cloudCostManagementSectionDescription'),
      },
    }
  },
  data: () => ({
    dashboardDataLoading: false,
    tagMappingDataLoading: false,
  }),
  computed: {
    ...mapState('organization/cloudCostManagement', {
      aggregateData: (state) => state.aggregateData,
      graphOrderingParam: (state) => state.graphOrderingParam,
      histogramDates: (state) => state.histogramDates,
      topProjects: (state) => state.topProjects,
    }),
    ...mapGetters('organization/cloudCostManagement', [
      'hasTagMapping',
    ]),
    hasEnoughProviderDatas () {
      return _.get(this.aggregateData, 'providerDatas', []).length > 1
    },
    isLoading () {
      return this.dashboardDataLoading || this.tagMappingDataLoading
    },
    series () {
      return this.parseToGraphFormat(this.topProjects.histograms, this.histogramDates)
    },
    seriesTotals () {
      return this.histogramDates.map((date) => {
        const cost = _.sumBy(_.values(this.series), (amounts) => _.find(amounts, ['date', date])?.cost)
        const co2e = _.sumBy(_.values(this.series), (amounts) => _.find(amounts, ['date', date])?.co2e)
        const kwh = _.sumBy(_.values(this.series), (amounts) => _.find(amounts, ['date', date])?.kwh)
        return { date, cost, co2e, kwh }
      })
    },
  },
  watch: {
    '$route.query.filters': {
      handler (newValue, oldValue) {
        if (oldValue && !newValue) this.UPDATE_URL_QUERY(this.$router)
      },
      deep: true,
    },
  },
  created () {
    const urlQueryParams = new URLSearchParams(window.location.search)
    if (urlQueryParams.get('filters')) {
      this.READ_URL_QUERY()
    } else {
      this.UPDATE_URL_QUERY(this.$router)
    }
  },
  mounted () {
    if (this.$cycloid.permissions.canDisplay('GetCloudCostManagementTagMapping')) {
      this.fetchTagMapping()
    }
  },
  methods: {
    ...mapActions('organization/cloudCostManagement', [
      'FETCH_DASHBOARD_DATA',
      'GET_TAG_MAPPING',
    ]),
    ...mapMutations('organization/cloudCostManagement', [
      'READ_URL_QUERY',
      'UPDATE_URL_QUERY',
    ]),
    gotoProviderDetail (providerCanonical) {
      const params = { providerCanonical, defaultQueryFilters: [{ key: 'group_by', value: ['service', null] }] }
      if (providerCanonical) this.$router.push({ name: 'cloudCostManagementProviderDetail', params })
    },
    parseToGraphFormat,
    async fetchDashboardData () {
      this.$toggle.dashboardDataLoading(true)
      await this.FETCH_DASHBOARD_DATA({ router: this.$router })
      this.$toggle.dashboardDataLoading(false)
    },
    async fetchTagMapping () {
      this.$toggle.tagMappingDataLoading(true)
      await this.GET_TAG_MAPPING()
      this.$toggle.tagMappingDataLoading(false)
    },
    getTrimmedSeries,
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.cloudCostManagement',
        noProjectsFound: 'No projects found!',
        tryDifferentFilters: 'Try another time range or a different filtering query.',
        providersBreakdown: 'Providers breakdown',
        overview: 'Overview',
      },
      es: {
        title: '@:routes.cloudCostManagement',
        noProjectsFound: '¡No se encontraron proyectos!',
        tryDifferentFilters: 'Pruebe con otro rango de tiempo o una consulta de filtrado diferente.',
        providersBreakdown: 'Desglose de proveedores',
        overview: 'Resumen',
      },
      fr: {
        title: '@:routes.cloudCostManagement',
        noProjectsFound: 'Aucun projet trouvé !',
        tryDifferentFilters: 'Essayez une autre période ou un autre ensemble de filtres.',
        providersBreakdown: 'Répartition par fournisseur',
        overview: 'Aperçu',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.layout {
  &__container {
    justify-content: space-between;
  }

  &__content-wrapper {
    flex-grow: 1;
    min-width: 0;
    padding-right: 16px;
    padding-left: 32px;
  }

  &__content {
    width: 1200px;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;

    .summary {
      display: flex;
      flex-wrap: wrap;

      .summary-banner {
        flex-basis: 600px;
        flex-grow: 9999;
        min-width: 600px;
      }

      .emissions-summary {
        flex-basis: 360px;
        flex-grow: 1;
        min-width: 360px;
      }
    }

    .no-projects {
      &__title {
        color: get-color("primary", "light-1");
        font-size: map.get($font-sizes, "xxl");
        font-weight: $font-weight-bold;
      }

      &__subtitle {
        color: get-color("primary", "light-3");
        font-size: map.get($font-sizes, "base");
        font-weight: $font-weight-bold;
      }
    }
  }

  &__providers-title {
    font-size: map.get($font-sizes, "xl");
  }

  &__graph-title {
    align-items: center;
    width: 100%;
    font-size: map.get($font-sizes, "xl");
  }

  &__provider-cards {
    $gap: 24px;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(384px, 1fr));
    margin-bottom: 48px;
    column-gap: $gap;
    row-gap: $gap;
  }

  &__sidebar {
    flex-shrink: 0;
    padding-right: 18px;
    padding-left: 18px;

    > * {
      width: 328px;
    }
  }
}
</style>
